/* Fondo con degradado dinámico */
.company-overview {
    padding: 4rem 2rem;
    background: linear-gradient(135deg, #b2ebf2, #e0f7fa, #66d2e0);
    border-top: 4px solid #cfd8dc;
    border-bottom: 4px solid #cfd8dc;
    animation: fadeInSection 0.8s ease-in-out;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  }
  
  /* Animación de entrada */
  @keyframes fadeInSection {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Contenido central */
  .company-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  
  /* Títulos elegantes */
  .company-title {
    font-size: 3.2rem;
    color: #004d40;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.8rem;
    letter-spacing: 1.2px;
    text-align: left;
  }
  
  .company-subtitle {
    font-size: 1.7rem;
    color: #00796b;
    margin-bottom: 1.8rem;
    line-height: 1.8;
    font-style: italic;
  }
  
  /* Texto estilizado */
  .company-text {
    flex: 1;
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
    line-height: 1.8;
    color: #37474f;
  }
  
  /* Botón animado (opcional para CTA) */
  .company-text .cta-button {
    display: inline-block;
    margin-top: 1.5rem;
    padding: 0.8rem 2rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(135deg, #00796b, #004d40);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    text-transform: uppercase;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .company-text .cta-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  }
  
  /* Imagen estilizada */
  .company-image-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeInImage 1.2s ease-in-out;
  }
  
  @keyframes fadeInImage {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .company-image {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
  }
  
  .company-image:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.4);
  }
  
  /* Animaciones suaves */
  .company-title,
  .company-text p {
    animation: textFadeIn 1.2s ease-in-out;
  }
  
  @keyframes textFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Gradientes animados para fondo (sutil) */
  .company-overview {
    background: linear-gradient(135deg, #b2ebf2, #e0f7fa, #66d2e0);
    animation: gradientShift 6s infinite;
  }
  
  @keyframes gradientShift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  /* Responsivo para tabletas */
  @media (max-width: 768px) {
    .company-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .company-title {
      font-size: 2.8rem;
    }
  
    .company-subtitle {
      font-size: 1.5rem;
    }
  
    .company-text {
      font-size: 1.2rem;
    }
  
    .company-image {
      margin-top: 2rem;
      max-width: 90%;
    }
  }
  
  /* Responsivo para móviles */
  @media (max-width: 480px) {
    .company-title {
      font-size: 2.4rem;
    }
  
    .company-subtitle {
      font-size: 1.3rem;
    }
  
    .company-text {
      font-size: 1rem;
    }
  
    .company-image {
      max-width: 100%;
    }
  }
  