html, body {
  height: 100%;
  width: 100vw; /* Ocupa el 100% del ancho de la pantalla */
  background: linear-gradient(135deg, #e0f7fa, #75e3f2, #19cae2);
}

.get-in-touch {
    min-height: 100vh;
    width: 100vw; /* Ocupa el 100% del ancho de la pantalla */
    background: linear-gradient(135deg, #e0f7fa, #75e3f2, #19cae2);
    display: flex;
    justify-content: center;
    padding: 2rem 0; /* Padding superior e inferior */
    box-sizing: border-box; /* Evita problemas de cálculo de ancho */
  }
  
  .get-in-touch-content {
    width: 100%; /* Asegura que el contenido ocupe el 100% */
    height: 100%;
    background: linear-gradient(135deg, #e0f7fa, #75e3f2, #19cae2);
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: stretch;
    box-sizing: border-box; /* Asegura que el padding no afecte el ancho */
  }
  
  /* Columnas que ocupan el 100% en pantallas pequeñas y 50% en pantallas grandes */
  .contact-text, .contact-form-container {
    background: rgba(255, 255, 255, 0.9);
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    width: 100%;
    flex: 1;
    box-sizing: border-box; /* Asegura que el padding no afecte el ancho */
  }
  
  /* Estilo columna izquierda */
  .contact-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .contact-text h3 {
    font-size: 2.5rem;
    color: #00796b;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }
  
  .contact-details a {
    color: #007bff;
    font-weight: bold;
  }
  
  .contact-details a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  /* Estilo columna derecha */
  .form-title {
    font-size: 2.5rem;
    color: #00796b;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group label {
    font-size: 1.2rem;
    color: #00796b;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #cccccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #00796b;
    outline: none;
  }
  
  .submit-btn {
    padding: 0.75rem;
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #00796b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #004d40;
  }
  
  /* Animaciones */
  .fade-in {
    opacity: 0;
    animation: fadeIn 1.2s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .slide-in {
    opacity: 0;
    animation: slideIn 1.5s ease-out forwards;
  }
  
  @keyframes slideIn {
    from { opacity: 0; transform: translateX(-100%); }
    to { opacity: 1; transform: translateX(0); }
  }
  
  /* Responsividad */
  @media (max-width: 992px) {
    .get-in-touch-content {
      flex-direction: column;
    }
  
    .contact-text, .contact-form-container {
      text-align: center;
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .contact-text h3, .form-title {
      font-size: 2.2rem;
    }
  
    .contact-text p, .contact-details p, .form-group label {
      font-size: 1rem;
    }
  
    .submit-btn {
      font-size: 1rem;
    }
  }
  