/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: #2D3748;
  scroll-behavior: smooth;
}

/* Galería profesional y responsiva */
.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Ajusta columnas dinámicamente */
  gap: 5px; /* Espaciado mínimo entre imágenes */
  padding: 5px;
  background-color: #ffffff; /* Fondo claro */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px; /* Bordes suaves del contenedor */
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px; /* Bordes suaves */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item:hover {
  transform: scale(1.03); /* Suave efecto de zoom */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15); /* Sombra profesional */
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta imágenes sin distorsión */
  border-radius: 10px;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.gallery-item:hover .gallery-image {
  transform: scale(1.05); /* Zoom suave */
  filter: brightness(1.15); /* Resalta los colores */
}

.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Fondo semitransparente */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
  opacity: 0.3; /* Aparece el overlay al pasar el ratón */
}

.gallery-icon {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 5px;
  transition: transform 0.2s ease;
}

.gallery-icon:hover {
  transform: scale(1.1); /* Resalta el icono */
}

.gallery-text {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
  text-shadow: 0px 2px 4px rgba(89, 197, 224, 0.4);
  text-align: center;
}

/* Responsividad avanzada */
@media (max-width: 1200px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Más columnas en pantallas grandes */
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Más compacta en tablets */
    gap: 8px;
  }
}

@media (max-width: 576px) {
  .gallery-container {
    grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
    gap: 5px;
  }
}

/* Responsividad avanzada */
@media (max-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Reduce columnas */
    gap: 2px;
  }
}

@media (max-width: 576px) {
  .gallery-container {
    grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
    gap: 2px;
  }
}

/* Modal */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(8px);
  overflow: hidden;
  padding: 0;
  cursor: default;
}

.modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  gap: 10px;
  animation: fadeIn 0.6s ease;
  border-radius: 0;
}

/* Botón de cierre */
.close-modal-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: linear-gradient(135deg, #ff6f61, #ff9e80);
  border: none;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  z-index: 999;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.close-modal-btn:hover {
  transform: scale(1.2);
  background: linear-gradient(135deg, #ff9e80, #ff6f61);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.5);
}

/* Imagen dentro del Swiper */
.swiper-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
  cursor: zoom-in;
}

.swiper-image:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.6);
}

/* Controles del Swiper */
.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  font-size: 22px;
  padding: 12px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  transform: scale(1.2);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.6);
}

/* Indicadores de paginación */
.swiper-pagination-bullet {
  background: rgba(0, 0, 0, 0.8);
  opacity: 1;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.swiper-pagination-bullet:hover {
  transform: scale(1.2);
  background: rgba(255, 255, 255, 0.8);
}

.swiper-pagination-bullet-active {
  background: #ff6f61;
  transform: scale(1.3);
}

/* Cursores personalizados */
.modal:hover {
  cursor: default;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  cursor: pointer;
}

/* Animación */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.gallery-icon {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
  transition: transform 0.3s ease;
}

.gallery-icon:hover {
  transform: scale(1.1); /* Pequeño efecto al pasar el cursor */
}

/* Responsividad */
@media (max-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Ajusta el tamaño mínimo */
    gap: 16px;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .close-modal-btn {
    top: 10px;
    right: 10px;
    padding: 8px;
    font-size: 14px;
  }

  .swiper-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media (max-width: 576px) {
  .gallery-container {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .swiper-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .close-modal-btn {
    padding: 6px;
    font-size: 12px;
  }
}

/* Solo en pantallas grandes (agregar aspect-ratio) */
@media (min-width: 500px) {
  .gallery-item {
    aspect-ratio: 1 / 1;
  }
}
