.company-overview {
    padding: 20px;
    background-color: #f7f7f7;
  }
  
  .construction-process {
    padding: 20px;
    background-color: #e0f7fa;
  }
  
  .get-in-touch {
    padding: 20px;
    background: linear-gradient(135deg, #e0f7fa, #75e3f2, #19cae2);
  }

  .empresa-page{
    background: linear-gradient(135deg, #e0f7fa, #75e3f2, #19cae2);
  }
  