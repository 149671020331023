/* Sección de construcción */
.construction-process {
    padding: 3rem 1.5rem;
    background: linear-gradient(135deg, #e0f7fa, #66d2e0);
    color: #333;
    font-family: 'Poppins', sans-serif;
    animation: fadeInSection 0.8s ease-in-out;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    margin-bottom: 0; /* Reduce el margen final */
    min-height: calc(100vh - 100px); /* Ajusta la altura mínima */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribuye el contenido verticalmente */
}

/* Animación de entrada para la sección */
@keyframes fadeInSection {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Contenedor principal con columnas */
.process-content {
    margin-top: 2rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
}

.process-content:last-of-type {
    margin-bottom: 0; /* Elimina margen inferior del último elemento */
}

/* Estilos para el texto */
.process-text {
    font-size: 1.4rem;
    line-height: 1.8;
    padding: 1.5rem;
    color: #37474f;
    animation: fadeInText 0.8s ease-in-out;
    flex: 1;
    text-align: justify;
}

@keyframes fadeInText {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Estilos para las imágenes */
.process-images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    animation: fadeInImage 0.8s ease-in-out;
}

@keyframes fadeInImage {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.building-image {
    max-width: 100%;
    width: 80%;
    height: auto;
    margin-top: 1rem;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.building-image:hover {
    transform: scale(1.08);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Estilos para el carrusel de imágenes en la fase final */
.image-carousel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.image-controls {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    pointer-events: none;
}

.image-control {
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    color: #fff;
    padding: 10px;
    font-size: 24px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s ease, transform 0.3s ease;
    pointer-events: all;
}

.image-control:hover {
    background-color: rgba(0, 0, 0, 0.9);
    transform: scale(1.2);
}

.image-control:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

/* Diseño responsive para tabletas */
@media (max-width: 768px) {
    .process-content {
        flex-direction: column;
    }

    .process-text,
    .process-images {
        text-align: center;
        margin-bottom: 2rem;
    }

    .building-image {
        width: 90%;
    }

    .image-control {
        padding: 8px;
        font-size: 20px;
    }
}

/* Diseño responsive para móviles */
@media (max-width: 480px) {
    .process-text {
        font-size: 1.2rem;
        padding: 1rem;
    }

    .building-image {
        width: 100%;
        margin: 0 auto;
    }

    .image-control {
        padding: 6px;
        font-size: 18px;
    }
}

/* Reducción del espacio final */
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
