/* Estilos generales para la sección de encabezado */
.header-section {
  position: relative;
  width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #e0f7fa, #05c1c8, #a6dcef);
  background-size: 200% 200%;
  animation: backgroundShift 6s ease-in-out infinite alternate, fadeIn 1s ease-in-out;
  overflow: hidden;
  margin-top: 70px;
  padding: 25px 0;
  box-sizing: border-box;
}

/* Fondo dinámico */
@keyframes backgroundShift {
  from {
    background-position: 0% 50%;
  }
  to {
    background-position: 100% 50%;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(5, 193, 200, 0.15);
  z-index: 1;
}

/* Contenedor de contenido */
.content-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  gap: 2rem;
  z-index: 2;
}

/* Columna de texto */
.text-column {
  flex: 0 0 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  animation: bounceIn 1.5s ease-in-out;
}

.header-content {
  text-align: center;
  padding: 20px;
}

.header-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #004d40;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.3s ease;
}

.header-title:hover {
  color: #002b27;
}

.header-subtitle {
  font-size: 1.4rem;
  color: #00796b;
  margin-bottom: 20px;
  font-style: italic;
  letter-spacing: 0.5px;
}

/* Botón de llamada a la acción */
.cta-button {
  background-color: #88e4f4;
  color: #faf3e0;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background-color: #05c1c8;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Columna de video */
.video-column {
  flex: 0 0 65%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
}

/* Estilo uniforme de los videos */
.header-video {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: zoomIn 1.2s ease;
}

.header-video:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Controles de video */
.video-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 2;
  padding: 0 20px;
  animation: fadeIn 1.5s ease-in-out;
}

.video-control {
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  color: #faf3e0;
  padding: 12px;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 50%;
}

.video-control:hover {
  background-color: rgba(0, 0, 0, 0.9);
  transform: scale(1.15);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .header-section {
    min-height: 70vh;
    margin-top: 65px;
    flex-direction: column;
    padding: 15px 0;
  }

  .header-title {
    font-size: 1.75rem; /* Reducción del 30% para pantallas pequeñas */
  }

  .text-column {
    width: 100%;
    padding: 10px;
    margin-top: 30px; /* Ajustado para bajar 10px más */
  }

  .video-column {
    width: 100%;
    padding: 10px;
  }

  .video-container {
    width: 125%;
    max-width: none;
    height: 300px;
    aspect-ratio: 16 / 9;
  }

  .header-video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

@media (max-width: 480px) {
  .header-section {
    min-height: 90vh;
    margin-top: 57px;
    padding: 15px 10px;
  }

  .header-title {
    font-size: 1.75rem; /* Reducción del 30% para pantallas pequeñas */
  }

  .text-column {
    width: 100%;
    padding: 10px;
    margin-top: 30px; /* Ajustado para bajar 10px más */
  }

  .video-column {
    width: 100%;
    padding: 10px;
  }

  .video-container {
    width: 125%;
    height: 300px;
    max-width: none;
    aspect-ratio: 16 / 9;
  }

  .header-video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .content-wrapper {
    gap: 1rem;
  }
}
