/* Estilo General de la Navbar */
.enhanced-navbar {
  background: linear-gradient(135deg, #004d40, #00796b); /* Gradiente profesional */
  color: #ffffff;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  animation: navbarFadeIn 0.8s ease-out;
  z-index: 1000;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Marca de la Navbar */
.enhanced-navbar .navbar-brand {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.enhanced-navbar .navbar-brand:hover {
  color: #b2dfdb;
  transform: scale(1.05);
}

/* Por defecto, mostrar la versión completa */
.brand-desktop {
  display: inline;
}

.brand-mobile {
  display: none;
}

/* Ajuste para dispositivos móviles */
@media (max-width: 576px) {
  .brand-desktop {
    display: none; /* Ocultar la versión completa */
  }

  .brand-mobile {
    display: inline; /* Mostrar la versión abreviada */
    font-size: 1.6rem; /* Ajuste del tamaño para móviles */
  }
}

/* Botón Toggler */
.navbar-toggler {
  border: none;
  background: transparent;
  outline: none;
  padding: 0.8rem;
  font-size: 1.4rem;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.navbar-toggler-icon {
  filter: invert(1); /* Hace el icono blanco */
}

.navbar-toggler:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.2);
}

/* Links de la Navbar */
.nav-link {
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 6px;
  transition: color 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
}

.nav-link:hover {
  color: #b2dfdb;
  background-color: rgba(178, 223, 219, 0.2);
  transform: scale(1.05);
}

/* Dropdown Styling */
.dropdown-menu {
  background: linear-gradient(135deg, #004d40, #00695c);
  border: none;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
  animation: fadeInDropdown 0.4s ease-out;
  overflow: hidden;
}

.dropdown-item {
  color: #ffffff;
  padding: 1rem 1.8rem;
  font-size: 1.1rem;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.dropdown-item:hover {
  background-color: #00897b;
  color: #ffffff;
  transform: scale(1.08);
}

/* Separador Opcional */
.dropdown-divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 1rem 0;
}

/* Animaciones */
@keyframes fadeInDropdown {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes navbarFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hover de la Dropdown Menu */
.dropdown-menu:hover {
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.4);
}

/* Efecto Sticky */
.enhanced-navbar.scrolled {
  background: linear-gradient(135deg, #00695c, #004d40);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
  animation: stickyAppear 0.5s ease-in;
}

@keyframes stickyAppear {
  from {
    opacity: 0.8;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsividad */
@media (max-width: 768px) {
  .enhanced-navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  .navbar-brand {
    font-size: 1.6rem;
  }

  .navbar-nav {
    flex-direction: column;
    gap: 0.5rem;
  }

  .nav-link,
  .dropdown-item {
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
    padding: 0.9rem 1.4rem;
  }

  .dropdown-menu {
    width: 100%;
    border-radius: 0;
    padding: 0.8rem;
  }

  .navbar-toggler {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .nav-link,
  .dropdown-item {
    font-size: 1.2rem;
    padding: 0.8rem 1.2rem;
  }

  .dropdown-menu {
    padding: 0.6rem;
  }
}

/* Animaciones Adicionales */
@keyframes dropdownScaleIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
