/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  background: linear-gradient(to bottom, #e0f7fa, #ffffff);
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: #2D3748;
  scroll-behavior: smooth;
}

/* Navbar Styles */
.navbar, .enhanced-navbar {
  background: linear-gradient(90deg, #88e4f4 0%, #05c1c8 100%);
  padding: 12px 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 10;
}

.navbar:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.navbar-brand {
  font-size: 1.8rem;
  color: #FAF3E0;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.navbar-brand:hover {
  color: #F1F8FF;
}

.navbar-toggler {
  border: none;
  background-color: #FAF3E0;
  border-radius: 4px;
  padding: 8px 12px;
  transition: background-color 0.3s ease;
}

.navbar-toggler:hover {
  background-color: #A8DADC;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(130,130,130,0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Navbar Links */
.navbar-nav {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.navbar-nav .nav-item {
  flex: 1;
  text-align: center;
}

.navbar-nav .nav-item .nav-link {
  font-size: 1.1rem;
  color: #FAF3E0;
  padding: 8px 12px;
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-nav .nav-item .nav-link:hover {
  color: #2D3748;
  background-color: #F1F8FF;
  transform: scale(1.05);
}

/* Section Styles */
section {
  padding: 40px 20px;
  background-color: #ffffff;
}

.about-section, .contact-section {
  text-align: center;
}

.about-section h2, .contact-section h2 {
  font-size: 2rem;
  color: #05c1c8;
  margin-bottom: 16px;
}

.about-section p, .contact-section p {
  font-size: 1.1rem;
  color: #2D3748;
  margin-bottom: 32px;
}

/* Buttons */
button, .btn {
  background-color: #88e4f4;
  color: #FAF3E0;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 16px;
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

button:hover, .btn:hover {
  background-color: #05c1c8;
  transform: translateY(-2px);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.4s ease-in-out;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
}

@media (max-width: 992px) {
  .navbar-nav {
    flex-direction: column;
  }

  .navbar-nav .nav-item .nav-link {
    font-size: 1rem;
    padding: 8px 10px;
  }

  section {
    padding: 32px 16px;
  }

}

@media (max-width: 768px) {
  .navbar {
    padding: 8px 16px;
  }

  button, .btn {
    font-size: 0.9rem;
    padding: 8px 12px;
  }

}

@media (max-width: 480px) {
  .navbar-toggler {
    padding: 6px 8px;
  }

  .navbar-nav .nav-item .nav-link {
    font-size: 0.9rem;
    padding: 6px 8px;
  }

}
