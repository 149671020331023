/* Estilo General */
body {
  font-family: 'Poppins', 'Roboto', sans-serif;
  background: linear-gradient(135deg, #b9f2f9, #e0f7fa);
  margin: 0;
  padding: 0;
  color: #333333;
  overflow-x: hidden;
}

/* Flecha para Scroll */
.scroll-arrow-wrapper {
  display: none; /* Por defecto está oculto */
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 10;
}

.scroll-text {
  font-size: 1.2rem;
  color: #004d40;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 6px 12px;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.scroll-arrow {
  font-size: 3rem;
  color: #004d40;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Mostrar flecha solo en pantallas pequeñas */
@media (max-width: 768px) {
  .scroll-arrow-wrapper {
    display: flex; /* Mostrar flecha en tabletas y móviles */
  }
}

@media (min-width: 769px) {
  .scroll-arrow-wrapper {
    display: none; /* Ocultar en pantallas grandes */
  }
}

/* Sección Principal */
.about-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #b2ebf2, #e0f7fa, #ffffff);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeInSection 0.8s ease-in-out;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Títulos y Subtítulos */
.about-title {
  font-size: 3rem;
  color: #004d40;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  animation: fadeInTitle 1s ease-in-out;
}

.about-subtitle {
  font-size: 1.5rem;
  color: #00695c;
  font-weight: 500;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 800px;
  line-height: 1.6;
}

.about-text {
  font-size: 1.2rem;
  color: #37474f;
  line-height: 1.7;
  margin: 0 auto 2rem auto;
  text-align: justify;
  max-width: 900px;
}

/* Botones */
.btn-primary {
  background-color: #66d2e0; /* Verde agua pastel */
  color: white;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
  background-color: #004d40; /* Tono más oscuro en hover */
  transform: scale(1.05);
}

/* Galería de Imágenes */
.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 2rem;
}
.gallery-container {
  padding: 5px;
  gap: 7px;
}

.gallery-item {
  background-color: #f9f9f9;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

.swiper-zoom-container img{
  object-fit: fill;
}

/* Animaciones */
@keyframes fadeInSection {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInTitle {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Modal */
.modal-content {
  width: 90%;
  height: 85%;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* Responsivo */
@media (max-width: 768px) {
  .about-title {
    font-size: 2.4rem;
  }

  .about-subtitle {
    font-size: 1.3rem;
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .gallery-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .btn-primary {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .gallery-image {
    height: 350px;
  }

  .modal-content {
    width: 95%;
    height: 80%;
  }
}

#videoAzulejoPorcelamico {
  width: 100%;
  height: 300px; /* Tamaño por defecto en escritorio */
  object-fit: fill; 
}

/* 📱 Ajuste para tablets y móviles */
@media screen and (max-width: 1024px) { /* Tablets y móviles */
  #videoAzulejoPorcelamico {
    height: 350px;
  }
}



